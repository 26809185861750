.dmain{
width: 100%;
height:fit-content;
display: flex;
flex-direction: column;
align-items: center;
justify-content:flex-start;
gap: 1rem;
overflow-x: hidden;
}

.dmain .topimg{

width: 100%;
height:fit-content;
overflow: hidden;
display: flex;
align-items:flex-start;
justify-content: center;

}

.dmain .topimg img{
background-size: contain;
background-position: center;
background-repeat: no-repeat;
width: 100%;
}

.dmain .r2{
width: 100%;
height:fit-content;
display:flex;
flex-wrap: wrap;
flex-direction: row;
align-items: center;
justify-content: center;
padding: 1rem;
gap: 6rem;
background-color: #aa67151c;
}

@media (max-width:1000px) {
    
    .dmain .r2{
        gap: 2rem;
    }


}




.dmain .r2 .left{
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    gap: 0rem;
}

.dmain .r2 .vline{

border-right: 0.0001cm solid #919295;
height: 11rem;
}


.dmain .r2 .left .fleetsize{
    font-size: 18px;
    font-family:Arial;
    color: black;
   font-weight: 540;
   line-height: 1.2em;
   
}

.dmain .r2 .left .detail{

    color: #919295;
    font-size: 16px;

}

.dmain .r2 .left .buttons{

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content:flex-start;
    gap: 0.3rem;
    padding-top: 1rem;
}

.dmain .r2 .left .buttons button{
background-color: white;
border: 1px solid #919295;
color: black;
padding-top: 0.3rem;
padding-bottom: 0.3rem;
padding-right: 0.7rem;
padding-left: 0.7rem;
border-radius: 3px 3px;
}

.dmain .r2 .left .buttons button:hover{
    color: white;
    background-color: #f09629;
    border:1px solid transparent;
}




.dmain .r2 .right{
    width: 23rem;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    gap: 0rem;
}


.dmain .r2 .right .fleetsize{
    font-size: 18px;
    font-family:Arial;
    color: black;
   font-weight: 540;
   line-height: 1.2em;
   
}

.dmain .r2 .right .detail{

    color: #919295;
    font-size: 17px;

}

.dmain .r2 .right .buttons{

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content:flex-start;
    gap: 0.3rem;
    padding-top: 1rem;
}

.dmain .r2 .right .buttons button{
background-color: white;
border: 1px solid #919295;
color: black;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
padding-right: 0.9rem;
padding-left: 0.9rem;
border-radius: 3px 3px;
}

.dmain .r2 .right .buttons button:hover{
    color: white;
    background-color: #f09629;
    border:1px solid transparent;
}


.dmain .r3{
    width: 100%;
    height:fit-content;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    background-color: #91929507;
    }


    .dmain .r3 .section1{

        background-color: #0e4154;
        width: 80%;
        height:fit-content;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content:space-between;
        overflow:auto;
        padding: 1.5rem;
    }




    @media (max-width:1000px) {
        .dmain .r3 .section1{
            width: 100%;
        }
    }

    .dmain .r3 .section1 .left{

        display: flex;
        flex-direction: column;
        align-items:flex-start;
        justify-content: center;
        color: white;

    }
    .dmain .r3 .section1 .right{

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        color: white;
    }


    .dmain .r3 .section1 .right .number{

        font-size:40px;
        font-family:Arial;

    }

    .dmain .r3 .section1 .right .button{
        border-radius: 50px 50px;
        border:1px solid #8b8b8b;
        overflow: hidden;
        width: 2.1rem;
        height: 2.1rem;
        display:flex ;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.1s ease; 
        background-color: #f09629;

    }
    .dmain .r3 .section1 .right .button:hover{
        background-color:white;

    }

    #dropicon{
        color: black;
        font-size: 60px;
    }
    #dropicon:hover{
        color: black;
    }


    .dmain .r3 .sectiondropdown{

       
        width: 80%;
        height: fit-content;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items:flex-start;
        justify-content: center;
        gap: 2rem;
        padding: 0.1rem;
        overflow: hidden;

    }

    @media (min-width:1000px) and (max-width:1317px) {
        
        .dmain .r3 .sectiondropdown{
            width: 95%;
            gap: 0rem;
            padding: 0rem;
        }
    
    }
    @media (max-width:2142px) {
        
        .dmain .r3 .sectiondropdown{
            width: 90%;
        }
    
    }

    .dmain .r3 .sectiondropdown .left{
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        justify-content: center;
        width: 30rem;
        height:fit-content;
        gap: 1rem;
    }


    .dmain .r3 .sectiondropdown .left .a1{
        font-size:40px;
        font-weight:bold;
    }

    .dmain .r3 .sectiondropdown .left .a2{
        color: #919295;
        font-size: 16px;
        
    }
    .dmain .r3 .sectiondropdown .left .a3{
        font-size: 16px;
    }
    .dmain .r3 .sectiondropdown .left .a4{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color:#f09629;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
 
    }
    .dmain .r3 .sectiondropdown .left .a5{
width: 100%;
background-color:rgb(245, 245, 245);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

    }


    .dmain .r3 .sectiondropdown .left .a5 form{
        width: 100%;
        background-color:rgb(245, 245, 245);
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        justify-content: center;
        gap: 1rem;
        padding: 1rem;

    }

    .dmain .r3 .sectiondropdown .left .a5 form label{
        font-size: 17px;
        font-weight: bold;
    }

    .dmain .r3 .sectiondropdown .left .a5 form button{
        border: none;
        background-color:#f09629;
        color: white;
        padding-right: 3rem;
        padding-left: 3rem;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        transition: 0.1s ease;
    }

    .dmain .r3 .sectiondropdown .left .a5 form button:hover{
        background-color:#0e4154;
        color: white;

    }

    .dmain .r3 .sectiondropdown .left .a5 form input{

        border: none;
        background-color: transparent;
        border-bottom: 1px solid gainsboro;
        outline: none;
        width: 100%;
    }



    .dmain .r3 .sectiondropdown .right{
        display: flex;
        flex-direction: column;
        align-items:flex-end;
        justify-content:flex-start;
        width: 35rem;
        height:fit-content;
        gap: 1rem;
    }

    .dmain .r3 .sectiondropdown .right .b1{
        display: flex;
        flex-direction: row;
        align-items:center;
        flex-wrap: wrap;
        justify-content:flex-end;
        gap: 2rem;
        overflow: hidden;
        width: 100%;
    }

    .dmain .r3 .sectiondropdown .right .b1 .n{
font-size: 60px;
    }

    .dmain .r3 .sectiondropdown .right .b1 .b{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px 50px;
        width: 2.1rem;
        height: 2.1rem;
        border: 1px solid black;
        cursor: pointer;
        transition: 0.1s ease;
            }

            .dmain .r3 .sectiondropdown .right .b1 .b:hover{
                background-color: #C93030;
                color: white;
            }



    .dmain .r3 .sectiondropdown .right .b2{
        width: 100%;
        height:fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }



    .dmain .r3 .sectiondropdown .oneliner{
        border: 1px solid black;
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }


    .dmain .r4{
width: 80%;
height:fit-content;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 1rem;
    }

    @media (max-width:1000px) {
        .dmain .r4{
            width: 100%;
            padding: 0.5rem;
        }
        
    }

    .dmain .r4 .upper{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 2rem;
        color: #f09629;
    }

    .dmain .r4 .upper .rlbutton{

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;

    }




    .dmain .r4 .lower{
width: 100%;
height:fit-content;
padding: 0.1rem;
overflow: hidden;
white-space:nowrap;
    }

    .dmain .r4 .lower .slider{
        height:fit-content;
        width:fit-content;
        overflow-x: auto;
        transition: 0.7s ease;
    }

@media (max-width:1000px) {

    .dmain .r4 .lower{
overflow: auto;
    }
    
}


    .dmain .r4 .lower .slider .box{
          position: relative;
        border: 1px solid rgb(192, 192, 192);
        display: inline-block;
        margin-inline: 1rem;
        text-align: center;
        width: 20rem;
        height: 18rem;
        cursor: pointer;
        transition: 0.1s ease;
        overflow: hidden;
        border-top: 6px solid #0e4154;
    }
    .dmain .r4 .lower .slider .box:hover{
        border-top: 6px solid  #fa9720;
    }


    .dmain .r4 .lower .slider .box::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../public/blurpic.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0; /* Initially hidden */
        transition: opacity 0.1s ease;
        z-index: 1; /* Ensure overlay is on top */
        
    }
    
    .dmain .r4 .lower .slider .box:hover::before {
        opacity: 0.1; /* Desired dim opacity */
     
    }
    
    .dmain .r4 .lower .slider .box .b1 {
        position: relative;
        z-index: 2; /* Ensure content is above overlay */
        color: inherit; /* Maintain text color on hover */
    }
    
  

    
    .dmain .r4 .lower .slider .box .b1{
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content:space-between;
overflow: hidden;

    }

    .dmain .r4 .lower .slider .box .b1 .upper{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 1rem;
    }
   .dmain .r4 .lower .slider .box .b1 .upper h2{

        font-size: 30px;
        font-weight: bold;
        color:#0e4154;
    }
    .dmain .r4 .lower .slider .box .b1 .upper p{

        font-size: 18px;
        color:#0e4154;
        white-space: wrap;
    }
    .dmain .r4 .lower .slider .box .b1 .lower{
display: flex;
flex-direction: row;
align-items: center;
justify-content:space-between;
width: 100%;
padding: 1rem;
border: none;

    }
    .dmain .r4 .lower .slider .box .b1 .lower .left{
       
        font-size: 35px;
        color:#0e4154;
    }
   

 















   









    .dmain .r5{
width: 80%;
height:fit-content;
display: flex;
flex-direction: column;
align-items:flex-start;
justify-content: center;
}

.dmain .r5 h2{
    color:#0e4154;
}

.dmain .r5 b{
    color: #f09629;
}

.dmain .dmainr6{

width: 80%;
height:fit-content;
display: flex;
flex-direction: row;
flex-wrap:wrap;
align-items: center;
justify-content:space-between;
gap: 1rem;
}

.dmain .dmainr6 .left{
border: 1px solid black;
background-color:#0e4154;
width: 35rem;
height:fit-content;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 2rem;
}


.dmain .dmainr6 .left form{

width: 100%;
height:fit-content;
display: flex;
flex-direction: column;
align-items:flex-start;
justify-content:flex-start;
color: white;
gap: 3rem;
overflow: hidden;
    
}


.dmain .dmainr6 .left form .message{
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content:flex-start;
gap: 1rem;
}

.dmain .dmainr6 .left form .message input::placeholder{
color: white;
}

.dmain .dmainr6 .left form .row{
    
    width:fit-content;

    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    gap: 1rem;
}

@media (max-width:1000px) {
    
    .dmain .dmainr6 .left form .row{
        display: flex;
        flex-direction: column;
    }

}

.dmain .dmainr6 .left form .row .i1{

    width: fit-content;
}
.dmain .dmainr6 .left form .row .i2{

    width: fit-content;
}
.dmain .dmainr6 .left form .row .i3{

    width: fit-content;
}

.dmain .dmainr6 .left form .row .i1 input{
    width: 8rem;
    
}

.dmain .dmainr6 .left form .row .i2 input{
    width: 8rem;
}
.dmain .dmainr6 .left form .row .i3 input{
    width: 8rem;
}


.dmain .dmainr6 .left form .row .i1 input::placeholder{
    color: white;
}
.dmain .dmainr6 .left form .row .i2 input::placeholder{
    color: white;
}
.dmain .dmainr6 .left form .row .i3 input::placeholder{
    color: white;
}

.dmain .dmainr6 .left form .send button{
    
background-color: transparent;
border: 1px solid white;
color: white;
padding-top: 0.7rem;
padding-bottom: 0.7rem;
padding-right: 3rem;
padding-left: 3rem;
transition: 0.1s ease;
font-size: 20px;
}

.dmain .dmainr6 .left form .send button:hover{
    background-color:#f09629;
    border: 1px solid transparent;
    
}


.dmain .dmainr6 .right{
    width:fit-content;
    height:fit-content;
    display: flex;
    align-items: center;
    justify-content: center;

}

.dmain .dmainr6 .right .image{

    width: 20rem;
    cursor: pointer;
}