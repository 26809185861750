.admintabs{
width: 100%;
height: 100vh;

display: flex;
flex-direction: column;
align-items: center;
justify-content:flex-start;
gap: 0.5rem;

}

.admintabs form{
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    width: 100%;
    height:fit-content;
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: center;
    padding: 1rem;
gap: 1rem;   

}

.admintabs form input{
    width: 20rem;
border: 1px solid #ff8c00;
padding: 0.3rem;
border-radius: 10px 10px;
outline: none;
}

.admintabs form select{
    width: 20rem;
    border: 1px solid #ff8c00;
    padding: 0.3rem;
    border-radius: 10px 10px;
    outline: none;
}

.admintabs form button{
    border: none;
    background-color: #ff8c00;
    color: white;
    padding: 0.5rem;
    border-radius: 10px 10px;
    border: 1px solid transparent;
}

.admintabs form button:hover{
background-color:white;
color:#ff8c00;
border: 1px solid #ff8c00;

}