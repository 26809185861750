.navbar{

width: 100%;
height:fit-content;
color: black;
display: flex;
flex-direction: column;
align-items: center;
justify-content:flex-start;
background-color: white;


}


.navbar .upper{
width: 100%;
height: 3rem;
position: relative;
display: flex;
flex-wrap: wrap;
flex-direction: row;
align-items:center;
justify-content:center;
border-top: 1px solid rgba(128, 128, 128, 0.301);
border-bottom: 1px solid rgba(128, 128, 128, 0.301);
position: relative;
bottom: 0.5rem;

}

.navbar .upper .upper2{

width: 80%;
height:100%;
position: relative;
display: flex;
flex-wrap: wrap;
flex-direction: row;
align-items:center;
justify-content:space-between;
}

.navbar .upper ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.navbar .upper .cart{
    cursor: pointer;
    transition: 0.1s ease;
      color: #0e4154;
}

.navbar .upper .cart:hover{
    color: #fa9720;
}


.navbar .upper ul li{
    border-right: 1px solid rgba(128, 128, 128, 0.301);
    padding-right: 0.7rem;
    padding-left: 0.7rem;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    cursor: pointer;
    border-bottom: 3px solid transparent;
    font-size: 13px;
    height: 100%;
    color: #0e4154;
    border-bottom: 2px solid transparent;
}

.navbar .upper ul li:hover{
  
    border-bottom: 2px solid #fa9720;


}



.navbar .lower{
    width: 100%;
    height:fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    overflow: hidden;

    }
    
    .navbar .lower .lower2{
        width: 80%;

        height:100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:space-between;

    }

    .navbar .lower .lower2 .left{

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4rem;
    }
    
    .navbar .lower .lower2 .left .imgdiv{
padding-top: 0.4rem;
    }

    .navbar .lower .lower2 .left img{
   background-size: contain;
   overflow: hidden;
   height:100%;
    }
    .navbar .lower .lower2 .left ul{
 
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        list-style-type: none;
        padding: 0;
        margin: 0;
        gap: 3rem;
    }

    .navbar .lower .lower2 .left ul li{

        cursor: pointer;
        font-weight: bold;
        font-size: 18px;
        transition: 0.1 ease;
    }

    .navbar .lower .lower2 .left ul li:hover{
        color: #fa9720;
    }

    .navbar .lower .lower2 .rights{

     
        text-align: right;
        height:100%;
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
    }
    .navbar .lower .lower2 .rights p{
        font-size:20px;
    }
    .navbar .lower .lower2 .rights span{
        font-size: 13px;
        color: rgb(105, 105, 105);
    }


    .active-link li{
        color: #fa9720;
    }