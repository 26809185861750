.Afootermain{
width: 100%;
height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content:flex-start;
gap: 1rem;
}

.Afootermain form{
    width: 100%;
    height: fit-content;
display: flex;
flex-wrap: wrap;
align-items:center;
justify-content: center;
gap: 3rem;
}

.Afootermain form .a1{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    gap: 1rem;
}
.Afootermain form .a2{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    gap: 1rem;
}
.Afootermain form .a3{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    gap: 1rem;
}
.Afootermain form .a4{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    gap: 1rem;
}
.Afootermain form .a5{
    display: flex;
flex-wrap: wrap;
    align-items:center;
    justify-content:center;
    gap: 1rem;
}

.Afootermain form .a6{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Afootermain form .a6 button{
    border: none;
    background-color:#ff8c00;
    color: white;
    padding: 0.4rem;
    border-radius: 10px 10px;
    border: 1px solid transparent;
    transition: 0.1s ease;
}
.Afootermain form .a6 button:hover{
    background-color: white;
    color: #ff8c00;
    border: 1px solid #ff8c00;
}

input{
    outline: none;
    border:1px solid #ff8c00;
    width: 17rem;
    padding: 0.3rem;
    border-radius: 10px 10px;
}