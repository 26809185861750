.tadmin{
width: 100%;
height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content:flex-start;
gap: 1rem;
background-color: rgb(255, 255, 255);
}



.tadmin .anavbar{
    width: 100%;
    position: fixed;
    height:fit-content;
    top: 0;
    left: 0;
    z-index: 1000;
    box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.1);
}


.tadmin .adminbody{
width: 100%;
height: 100vh;
margin-top: 7.5rem;
background-color: white;
overflow: hidden;
overflow-y: auto;
overflow-x: hidden;

}

.tadminlogin{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items:flex-start;
    justify-content: center;
    gap: 1rem;
    overflow: hidden;
}


.tadminlogin form{
margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 1rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    height:fit-content;
}

.tadminlogin form button{
    border: none;
    border: 1px solid transparent;
    background-color: #fa9720;
    color:white;
    padding: 0.5rem;
    border-radius: 10px 10px;
    padding-right: 2rem;
    padding-left: 2rem;
    transition: 0.2s ease;
}
.tadminlogin form button:hover{
    background-color: white;
    color: #fa9720;
    border: 1px solid #fa9720;
}