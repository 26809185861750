#anavbarl{

    cursor: pointer;
    font-family: sans-serif;
    border: 1px solid transparent;
    border-radius: 7px 7px;
}

#anavbarl:hover{

border: 1px solid #fa9720;
border-radius: 7px 7px;

}

.admin-link #anavbarl{
    border: 1px solid #fa9720;
    color: #fa9720;
    border-radius: 10px 10px;
}