.cformmain{
    width: 100%;
    height: 100vh;
    display:flex;
    justify-content: center;
    align-items: center;


}

.customer-portal-container {
    width: 80%;
    max-width: 1200px;
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.customer-portal-title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.customer-contact-card {
    background-color: #fafafa;
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.customer-contact-card:hover {
    transform: scale(1.02);
}

.customer-contact-name {
    margin-bottom: 10px;
    color: #333;
}

.customer-contact-email,
.customer-contact-phone,
.customer-contact-message {
    margin-bottom: 8px;
    color: #555;
}