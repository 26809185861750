.mlayout{
width: 100%;
height:fit-content;

}


.mlayout .navigation{
width: 100%;
height:fit-content;
overflow: hidden;
position: fixed;
top: 0;
left: 0;
z-index: 1000;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}



.mlayout .mobilenavbar{
    display: none;
}


.mlayout .body{
width: 100%;
overflow: hidden;
position: relative;
height:fit-content;
margin-top: 12rem;
overflow: auto;
}


@media (max-width:1000px) {

    .mlayout .navigation{
        display: none;
    }

    .mlayout .mobilenavbar{
        display:block;
        width: 100%;
        height:fit-content;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    }

    .mlayout .body{
        width: 100%;
        overflow: hidden;
        position: relative;
        height:fit-content;
        margin-top: 8vh;
        overflow: auto;
    }

}