.truckingfooter{
width: 100%;
height:fit-content;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 3rem;
padding: 1rem;
background-color: whitesmoke;
}

.truckingfooter .fr1{

    width: 100%;
    font-size: 25px;
    font-weight: bold;
    color:#0e4154;
    text-align: center;

}
.truckingfooter .fr2{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content:center;
    gap: 1rem;
    color:#0e4154;
}

.truckingfooter .fr2 .boxf{
    width: 15rem;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    gap: 0.5rem;
}

.truckingfooter .fr3{

width: 100%;
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items:flex-start;
justify-content:center;
gap: 3rem;

}

.truckingfooter .fr3 .left{

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

}
.truckingfooter .fr3 .center{

    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    color: #0e4154;

}


.truckingfooter .fr3 .center ul{
    display: flex;
flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    gap: 1rem;
    list-style-type: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
.truckingfooter .fr3 .center ul li{
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.truckingfooter .fr3 .center ul li:hover{
color:#f09629 ;
}



.truckingfooter .fr3 .right{

    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    gap: 1rem;
    color: #0e4154;
   

}

.truckingfooter .fr3 .right .rlink{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
}